"use client";
import type { NextComponentType } from "next";
import { appWithTranslation } from "next-i18next";
import { useRouter } from "next/router";
import { Fragment, useEffect } from "react";
import { useUserStore } from "store/useUserStore";

import "react-phone-input-2/lib/style.css";
import "react-toastify/dist/ReactToastify.css";
import "tailwindcss/tailwind.css";
import "yet-another-react-lightbox/styles.css";
import "../styles/globals.css";

import ChatBox from "components/chat/chatBox";
import MobileAppAdBanner from "components/ui/mobileAppAdBanner";
import TagManager from "react-gtm-module";
import { ToastContainer } from "react-toastify";
import { generateToken } from "utils/helpers/_general";

import { useAmplitudeTracker } from "hooks/useAmplitudeTracker";
import { useMixPanelTracker } from "hooks/useMixPanelTracker";
import { useSingularTracker } from "hooks/useSingularTracker";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

import { FpjsProvider } from "@fingerprintjs/fingerprintjs-pro-react";
import Fingerprint from "components/fingerprint/fingerprint";

interface AppProps {
  Component: NextComponentType;
}

const MyApp = ({ Component, pageProps: { ...pageProps } }: AppProps | any) => {
  const { locale, isFallback } = useRouter();
  const { deviceToken, setDeviceToken, setVisitorId }: any = useUserStore();

  useSingularTracker();
  useMixPanelTracker();
  useAmplitudeTracker();

  useEffect(() => {
    TagManager.initialize({ gtmId: "G-G81N815ZRZ" });
    const getDeviceToken = async () => {
      const tokenGenerated = generateToken(64) as string;
      setDeviceToken(tokenGenerated);
    };
    if (!deviceToken) getDeviceToken();
  }, []);

  useEffect(() => {
    const dir = locale == "ar" ? "rtl" : "ltr";
    const lang = locale!;
    document.querySelector("html")?.setAttribute("dir", dir);
    document.querySelector("html")?.setAttribute("lang", lang);
  }, [locale]);

  if (isFallback) return null; //Todo Create loading spinner fallback
  return (
    <FpjsProvider
      loadOptions={{
        apiKey: process.env.NEXT_PUBLIC_FINGERPRINT_API_KEY as string,
        endpoint: ["https://socket.macqueen.co"],
        scriptUrlPattern: [
          "https://socket.macqueen.co/web/v<version>/<apiKey>/loader_v<loaderVersion>.js",
        ],
      }}
    >
      <Fragment>
        <MobileAppAdBanner />
        <ToastContainer
          position="top-center"
          hideProgressBar={true}
          rtl={locale == "ar"}
        />
        <GoogleReCaptchaProvider
          reCaptchaKey={process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY as string}
        >
          <Fingerprint>
            <Component {...pageProps} />
          </Fingerprint>
        </GoogleReCaptchaProvider>
        <ChatBox />
      </Fragment>
    </FpjsProvider>
  );
};

export default appWithTranslation(MyApp);

//Stashed Code**********************************************************************************************************************

// const getNationalityCode = async () => {
//   await navigator.geolocation.getCurrentPosition(async (position) => {
//     const countryCode = await getLocationCountryCode({
//       lat: position.coords.latitude,
//       lng: position.coords.longitude,
//     });
//     setNationalityCode(countryCode);
//   });
// };

// if (!nationalityCode) getNationalityCode();

// const getLocationCountryCode = async (coords: { lat: number; lng: number }) => {
//   const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${coords.lat},${coords.lng}&key=${process.env.NEXT_PUBLIC_GEO_CODE_API_KEY}`;
//   const res = await axios.get<any>(url);
//   for (const result of res?.data?.results ?? [])
//     for (const component of result?.address_components ?? [])
//       if (component?.types?.includes("country"))
//         return component?.short_name.toLowerCase() ?? "";
//   return "";
// };
