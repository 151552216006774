/** @type {import('tailwindcss').Config} */
module.exports = {
  content: [
    "./app/**/*.{js,ts,jsx,tsx}",
    "./pages/**/*.{js,ts,jsx,tsx}",
    "./components/**/*.{js,ts,jsx,tsx}",
  ],
  theme: {
    extend: {
      backgroundImage: {
        "custom-gradient":
          "linear-gradient(204.58deg, #600E3D 32.89%, #9E1F63 100%)",
      },
    },
  },
  plugins: [require("daisyui")],
  daisyui: {
    themes: [
      {
        main: {
          primary: "#600E3D",
          secondary: "#9E1F63",
          accent: "#DFCD00",
          neutral: "#F2F7FB",
          "base-100": "#FFFFFF",
          info: "#3ABFF8",
          success: "#47DB85",
          warning: "#FBBD23",
          error: "#ef4444",
        },
        green: {
          primary: "#079d61",
          secondary: "#02522e",
          accent: "#DFCD00",
          neutral: "#f4fbf2",
          "base-100": "#FFFFFF",
          info: "#3ABFF8",
          success: "#47DB85",
          warning: "#FBBD23",
          error: "#ef4444",
        },
      },
    ],
  },
};
