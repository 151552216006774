import axios from "axios";

import { i18n } from "next-i18next";
import { useUserStore } from "store/useUserStore";

 
export const axiosInstance = axios.create({
  baseURL: process.env.NEXT_PUBLIC_BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

axiosInstance.interceptors.request.use((configs) => {
  const token = useUserStore.getState().token;
  const visitorId = useUserStore.getState().visitorId;
   const locale = i18n?.language;
  configs.headers["lang"] = locale;
  configs.headers.visitorId = visitorId;
  if (token) configs.headers.Authorization = `Bearer ${token}`;
  return configs;
});
