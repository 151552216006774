import { useTheme } from "hooks/useTheme";
import { useRouter } from "next/router";
import { useState, useEffect } from "react";
import FreshChat from "react-freshchat";

function ChatBox() {
  const [isMdSize, setIsMdSize] = useState(false);
  const [loadFreshChat, setLoadFreshChat] = useState(false);

  const router = useRouter();
  const { themeColors } = useTheme();
  useEffect(() => {
    setIsMdSize(window.innerWidth < 768);
    setLoadFreshChat(true);
  }, []);
  return (
    <>
      {loadFreshChat && (
        <FreshChat
          token={process.env.NEXT_PUBLIC_FRESH_CHAT_TOKEN ?? ""}
          email={process.env.NEXT_PUBLIC_FRESH_CHAT_EMAIL ?? ""}
          first_name=""
          locale={router.locale}
          config={{
            cssNames: {
              widget: `${
                isMdSize ? "custom-fc-frame-mobile" : "custom-fc-frame-desktop"
              } ${
                router.locale === "ar"
                  ? "custom-fc-frame-ar"
                  : "custom-fc-frame-en"
              }`,
            },
            headerProperty: {
              backgroundColor: themeColors.primary,
              foregroundColor: "#ffffff",
              border: "none",
            },
          }}
          onInit={(widget: any) => {
            widget.user.setProperties({
              email: "",
              first_name: "Guest",
              last_name: "",
              phone: "",
            });
          }}
        />
      )}
    </>
  );
}

export default ChatBox;
