import { useVisitorData } from "@fingerprintjs/fingerprintjs-pro-react";
import { ReactNode, useEffect } from "react";
import { useUserStore } from "store/useUserStore";

const Fingerprint = ({ children }: { children?: ReactNode }) => {
  const { getData: getFPData } = useVisitorData(
    { extendedResult: true },
    { immediate: true }
  );
  const { setVisitorId }: any = useUserStore();

  useEffect(() => {
    getFPData().then((data) => setVisitorId(data.visitorId));
  }, []);

  return <>{children}</>;
};

export default Fingerprint;
